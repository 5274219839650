
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  toRefs,
  watch,
  PropType,
  ref
} from 'vue';
import resize from '@/components/charts/mixins/resize';
import { init, EChartsOption } from 'echarts';
import dayjs from 'dayjs';
import { getUserPieChartRecordsByCampaign } from '@/services/api';

const formatTime = (time: string) => {
  if (!time) return;

  const TIME_FORMAT = 'YYYY-MM-DD';
  return dayjs(time).format(TIME_FORMAT);
};

export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    channelId: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    const { dateRange, channelId } = toRefs(props);
    const { mounted, chart, beforeDestroy, activated, deactivated } = resize();
    const dateValues = ref(dateRange.value);
    watch(dateRange, (dateRange) => {
      (dateValues.value) = dateRange;
    });

    const renderChart = async() => {
      if (channelId.value) {
        const { data } = await getUserPieChartRecordsByCampaign({
          channelId: `${channelId.value}`,
          query: {
            ...(dateRange.value && {
              date: `${dateRange.value[0]} ${dateRange.value[1]}`
            })
          }
        });

        const pieChart = init(
        document.getElementById('userPieCharts') as HTMLDivElement,
        'macarons'
        );
        pieChart.setOption({
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          legend: {
          // orient: 'vertical',
          // left: 'left'
            top: 'bottom'
          },
          series: [
            {
              color: ['#435EBE', '#5DDAB4', '#9694FF', '#FF7976', '#57CAEB'],
              name: 'Campaign Subscribers',
              type: 'pie',
              data: data || [],
              animationEasing: 'cubicInOut',
              animationDuration: 2000,
              // TODO: 暂时关闭 label
              label: {
                show: false,
                position: 'center'
              }
            }
          ]
        } as EChartsOption);
        chart.value = pieChart;
      }
    };

    watch([channelId, dateRange], () => {
      renderChart();
    });

    onMounted(() => {
      mounted();
      nextTick(() => {
        renderChart();
      });
    });

    onBeforeUnmount(() => {
      // if (!chart.value) {
      //   return
      // }
      // chart.value.dispose()
      // chart.value = null
      beforeDestroy();
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {
      formatTime
    };
  }
});
