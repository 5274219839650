
import { getSubscriptionReferrals, ChannelSubscriptionReferralsResponse } from '@/services/api';
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  setup() {
    const data = ref<ChannelSubscriptionReferralsResponse>({});
    const channelId = ref('');
    const date = useRoute().query.date as string;

    const getChannelId = () => {
      channelId.value = useRoute().query.channelId
        ? useRoute().query.channelId as string : '';
    };

    const fetchSubscriberSource = async() => {
      const res = await getSubscriptionReferrals({
        query: {
          date: date,
          channelId: channelId.value
        }
      });
      data.value = res;
    };

    onMounted(async() => {
      getChannelId();

      fetchSubscriberSource();
    });

    return {
      data
    };
  }
});
