import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_table, {
        data: _ctx.data?.data,
        border: "",
        fit: "",
        "highlight-current-row": "",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "NAME"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "AMOUNT"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.amount), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "CREATED AT"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.createdAt), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _: 1
  }))
}