
import { ref, onMounted, watch } from 'vue';
import PanelGroup from './components/PanelGroup.vue';
import LineChartRevenue from './components/LineChartRevenue.vue';
import LineChartUser from './components/LineChartUser.vue';
import PieChartUser from './components/PieChartUser.vue';
import PieChartRevenue from './components/PieChartRevenue.vue';
import ChannelCampaignDetail from './components/ChannelCampaignDetail.vue';
import DateRangePicker from '@/components/date-range-picker/Index.vue';
import { getChannels, MAX_PER_PAGE } from '@/services/api';
import dayjs from 'dayjs';

export default {
  components: {
    PanelGroup,
    LineChartRevenue,
    LineChartUser,
    PieChartUser,
    PieChartRevenue,
    ChannelCampaignDetail,
    DateRangePicker
  },
  setup() {
    const defaultStartTime = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
    const defaultEndTime = dayjs().format('YYYY-MM-DD');
    const dateRange = ref([defaultStartTime, defaultEndTime]);
    const channelId = ref(null);

    const channelOptions = ref([]);
    onMounted(async() => {
      const { data } = await getChannels({
        query: {
          perPage: MAX_PER_PAGE
        }
      });
      channelId.value = data[0].id;
      channelOptions.value = data;
    });

    const setDateRange = (date: any) => {
      dateRange.value = [];
      date.forEach(element => {
        dateRange.value.push(element);
      });
    };

    return {
      dateRange,
      channelOptions,
      channelId,
      setDateRange
    };
  }
};
