
import { getChannelRecord, DashboardChannelListResponse } from '@/services/api';
import { defineComponent, ref, toRefs, onMounted, watch, PropType } from 'vue';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';


const formatTime = (time: string) => {
  if (!time) return;

  const TIME_FORMAT = 'YYYY-MM-DD';
  return dayjs(time).format(TIME_FORMAT);
};

export default defineComponent({
  props: {
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    channelId: {
      type: Number || String,
      default: 0
    }
  },
  emits: ['sortChange'],
  setup(props, { emit }) {
    const { dateRange, channelId } = toRefs(props);
    const page = ref(1);
    const data = ref<DashboardChannelListResponse>({});
    const dateValues = ref(dateRange.value);
    const { t } = useI18n();

    // const sortOrder = ref('desc');
    const sortOrder = ref({
      order: 'desc',
      prop: ''
    });

    watch(dateRange, (dateRange) => {
      (dateValues.value) = dateRange;
    });

    const fetchChannelRecord = async() => {
      const res = await getChannelRecord({
        query: {
          page: page.value,
          sort: sortOrder.value.order,
          orderBy: sortOrder.value.prop,
          ...(dateRange.value && {
            date: `${dateRange.value[0]} ${dateRange.value[1]}`
          }),
          ...((channelId.value || channelId.value === '') && {
            channelId: channelId.value
          })
        }
      });

      data.value = res;
    };

    const sortMethod = (row: any) => {
      sortOrder.value.order = row.order === 'ascending' ? 'asc' : 'desc';
      sortOrder.value.prop = row.prop;

      fetchChannelRecord();
    };

    watch([dateRange, page, channelId], () => {
      fetchChannelRecord();
    });

    onMounted(async() => {
      fetchChannelRecord();
    });

    return {
      page,
      data,
      formatTime,
      t,
      sortMethod
    };
  }
});
