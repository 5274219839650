
import { defineComponent, ref, onMounted, watch } from 'vue';
import { CountTo } from 'vue3-count-to';

import { getChannelTodayRecord, getChannelsTodayRecord } from '@/services/api';

export default defineComponent({
  components: {
    CountTo
  },
  props: {
    channelId: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const todayRecord = ref({});
    onMounted(async() => {
      const { data } = await getChannelTodayRecord();
      todayRecord.value = data;
    });
    const fetchChannelsTodayRecord = async(channelId: any) => {
      // todayRecord.value = (await getChannelsTodayRecord(channelId));
      todayRecord.value = (await getChannelsTodayRecord(channelId)).data;
    };
    watch(() => props.channelId, (channelId) => {
      fetchChannelsTodayRecord(channelId);
    });

    return {
      todayRecord
    };
  }
});
