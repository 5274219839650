
import { nextTick, defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, PropType, ref, toRefs, watch } from 'vue';
import resize from '@/components/charts/mixins/resize';
import { init, EChartsOption } from 'echarts';

import { getRevenuePieChartRecords } from '@/services/api';
import dayjs from 'dayjs';

const formatTime = (time: string) => {
  if (!time) return;

  const TIME_FORMAT = 'YYYY-MM-DD';
  return dayjs(time).format(TIME_FORMAT);
};

export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props) {
    const { dateRange } = toRefs(props);
    const dateValues = ref(dateRange.value);
    watch(dateRange, (dateRange) => {
      (dateValues.value) = dateRange;
    });

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize();

    const dashboardData = ref([]);

    const getDashboardData = async() => {
      const { data } = await getRevenuePieChartRecords({
        query: {
          ...(dateRange.value && {
            date: `${dateRange.value[0]} ${dateRange.value[1]}`
          })
        }
      });
      dashboardData.value = data;
    };

    const setOptions = () => {
      const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
      unwarp(chart.value).setOption({
        xAxis: {
          type: 'category',
          data: dashboardData.value.map(({ name }) => {
            return name;
          }),
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value'
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 0,
          top: 15,
          containLabel: true
        },
        series: [
          {
            data: dashboardData.value.map(({ value }) => {
              return value;
            }),
            type: 'bar',
            itemStyle: {
              lineStyle: {
                width: 2
              }
            },
            animationDuration: 1000,
            animationEasing: 'cubicInOut'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: 8
        }
      } as EChartsOption);
    };

    watch([dateRange, dashboardData], () => {
      setOptions();
    });

    watch([dateRange], () => {
      getDashboardData();
    });

    const initChart = () => {
      const barChart = init(document.getElementById('revenueBarCharts') as HTMLDivElement, 'macarons');
      chart.value = barChart;
    };

    onMounted(() => {
      mounted();
      getDashboardData();
      nextTick(() => {
        initChart();
        setOptions();
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      if (!chart.value) {
        return;
      }
      chart.value.dispose();
      chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {
      formatTime
    };
  }
});
