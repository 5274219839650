
import { defineComponent, ref, onMounted, PropType, watch, toRefs } from 'vue';
import { CountTo } from 'vue3-count-to';
import { getChannelTodayRecord, getChannelPeriodStatistic } from '@/services/api';

export default defineComponent({
  components: {
    CountTo
  },
  props: {
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    channelId: {
      type: Number || String,
      default: 0
    }
  },
  setup(props) {
    const todayRecord = ref({});
    const { dateRange, channelId } = toRefs(props);

    const fetchChannelTodayRecord = async() => {
      // const { data } = await getChannelTodayRecord({
      //   query: {
      //     // 2021-12-05 2021-12-31
      //     ...(dateRange.value && {
      //       date: `${dateRange.value[0]} ${dateRange.value[1]}`
      //     })
      //   }
      // }
      // );

      const { data } = await getChannelPeriodStatistic({
        query: {
          // 2021-12-05 2021-12-31
          ...(dateRange.value && {
            date: `${dateRange.value[0]} ${dateRange.value[1]}`
          }),
          ...((channelId.value || channelId.value === '') && {

            channelId: channelId.value
          })
        }
      }
      );

      todayRecord.value = data;
    };

    onMounted(async() => {
      fetchChannelTodayRecord();
    });

    watch([dateRange, channelId], () => {
      fetchChannelTodayRecord();
    });

    return {
      todayRecord
    };
  }
});
